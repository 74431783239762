import React, { useEffect, useState } from 'react';
import './css/Alert.css';


interface AlertProps {
  message: string;
  type?: 'success' | 'error'; // アラートの種類
  onClose: () => void; // アラートを閉じるためのコールバック
}

const Alert: React.FC<AlertProps> = ({ message, type = 'success', onClose }) => {
  const [isVisible, setIsVisible] = useState(true); // 表示状態を管理

  useEffect(() => {
    console.log('Alert component mounted');

    // 3秒後にフェードアウト
    const timer = setTimeout(() => {
      setIsVisible(false); // フェードアウトを開始
      setTimeout(onClose, 500); // フェードアウト後に完全に削除
    }, 3000); // 3秒後にフェードアウト開始

    return () => {
      clearTimeout(timer); // アンマウント時にタイマーをクリア
      console.log('Alert component unmounted');
    };
  }, []); // 依存配列を空にして初回マウント時のみ実行

  return (
    <div className={`alert ${isVisible ? 'fade-in' : 'fade-out'} ${type}`}>
      {message}
    </div>
  );
};

export default Alert;
