import React, { useEffect, useState, useRef} from 'react';
import { Sparkles, Send } from 'lucide-react';
import { useParams } from "react-router-dom";
import { db } from './firebase';
import { doc, getDoc, DocumentData } from 'firebase/firestore';
import { getPaymentHistory, recipientUpdate , generateReview, reviewSend, incrementSessionCount} from './api';
import './css/reviewForm.css'
import { v4 as uuidv4 } from "uuid"; // セッションID生成用

const ReviewForm = () => {
  // 状態の初期化
  const { docId } = useParams();  // URLのドキュメントIDを取得
  
  const [review, setReview] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true); // ロード状態
  const [isCopied, setIsCopied] = useState(false);
  const reviewTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedTreat, setSelectedTreat] = useState('');
  const [transitionUrl, setTransitionUrl] = useState(''); // 遷移先URL
  const [data, setData] = useState<DocumentData | null>(null); // レビューデータ
  const [questions, setQuestions] = useState<any[]>([]); // 質問集
  const [treats, setTreats] = useState([]); // 質問集
  const [notFound, setNotFound] = useState(false);
  const [comments, setComments] = useState<any[]>([]); // 過去投稿内容

  const [textAreaChanged, setTextAreaChanged] = useState(false);

  type Question = {
    id: string; // 質問ID
    text: string; // 質問のテキスト
    options: string[]; // レーティングのオプション
  };

  type Ratings = {
    [key: string]: number | string; // key は質問テキスト、値は評価の数値
  };

  const [ratings, setRatings] = useState<Ratings>({});// 質問の回答を管理する

  useEffect(() => {

    document.title = 'レビュー投稿にご協力ください';
    const initialize = async () => {
      setIsSubmitting(true);
      try {
        if(docId){
          

          const docRef = doc(db, "reviewPageIds", docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {

            const sessionKey = `session_${docId}`; // セッションキーを定義
            const existingSessionId = sessionStorage.getItem(sessionKey); // セッションストレージから取得

            // セッションがない場合のみインクリメント
            if (!existingSessionId) {
              const newSessionId = uuidv4(); // 新しいセッションIDを生成
              sessionStorage.setItem(sessionKey, newSessionId); // セッションIDをストレージに保存

              // Firestoreでのセッションカウントをインクリメント
              await incrementSessionCount(docId);
            }
            
            const dbdata = docSnap.data();

            if (dbdata.status === false) {
              setNotFound(true); // 404ステートをtrueに設定
              return;
            }

            setData(dbdata);
            setTransitionUrl(dbdata.transitionUrl);

            const commentArray = dbdata.normalComments;
            if(commentArray){
              setComments(commentArray.reverse().slice(0, 9));
            }

            // 連番のquestionsを取得 (例: questions1, questions2, questions3)
            const fetchedQuestions = [];
            for (let i = 1; i <= 3; i++) { // ここで3つの質問を取得する例
              const question = dbdata[`question${i}`]; // `questions1`, `questions2`, `questions3` を順番に取得
              if (question) {
                fetchedQuestions.push({
                  id: question.text,
                  ...question,
                });
              }
            }
            setQuestions(fetchedQuestions);
            setTreats(dbdata['treats'])
            
            // レーティングの初期化: 取得したquestionsに基づいて
            const initialRatings = fetchedQuestions.reduce((acc, question) => {
              return { ...acc, [question.id]: "" };
            }, {});
            setRatings(initialRatings);
          }else{
            setNotFound(true); // 404ステートをtrueに設定
            return;
          }

        } else {
          console.log('存在しない');
        }

        setIsSubmitting(false);
        
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsSubmitting(false);
      }
    };
    initialize();
  }, [docId]);

  // レーティングの変更ハンドラ
  // レーティングの変更ハンドラ
  const handleRatingChange = (questionId: string, value: number) => {

    setRatings((prevRatings) => ({
      ...prevRatings,
      [questionId]: value,
    }));
  };

  // レビューテキストの変更ハンドラ
  const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (reviewTextAreaRef.current) {
      setReview(e.target.value);
      setTextAreaChanged(true);
    }
  };

  // レビュー自動生成関数
  const handleGenerateReview  = async () => {
    setIsGenerating(true);
    try {

      if (
        !ratings ||
        typeof ratings !== 'object' ||
        Object.values(ratings).some(value => value === '' || value == null) ||
        selectedTreat === ''
      ) {
        alert('評価項目に空の値があります。全ての項目を入力してください。');
      }else{

        type RatingLabel = "0" | "5" | "10";

        // 評価値のマッピングを定義
        const ratingMap: { [key in 0 | 1 | 2]: RatingLabel } = {
          0: "0",
          1: "5",
          2: "10"
        };

        // 新しいオブジェクトに変換
        const mappedRatings = Object.fromEntries(
          Object.entries(ratings).map(([key, value]) => [key, ratingMap[value as 0 | 1 | 2]])
        );

        if(data){
          const generatedReview = await generateReview(
            mappedRatings,
            data.industry,
            selectedTreat,
            comments);
          setReview(generatedReview);
          setTextAreaChanged(false);

        }else{
          setReview('予期せぬエラーがおきました');
        }
        
      }
    } catch (error) {
      setReview('レビューの下書き作成に失敗しました。手動で入力してください。');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopy = () => {
    if (reviewTextAreaRef.current) {
      navigator.clipboard.writeText(reviewTextAreaRef.current.value)
        .then(() => {
          setIsCopied(true);
        })
        .catch(err => {
          console.error('テキストのコピーに失敗しました:', err);
        });
    }
  };

  // プルダウンハンドリング
  const handleTreatChange = (newTreat: string) => {
    setSelectedTreat(newTreat); // 新しく選択されたTreatの値を状態に保存
  };
  
  // テキストエリア未入力チェック
  const isSubmitDisabled = () => {
    return review.trim() === '';
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('テキストがクリップボードにコピーされました');
    }, (err) => {
      console.error('クリップボードへのコピーに失敗しました', err);
    });
  };

  const simulateRedirect = (url: string) => {
    window.location.href = url;
  };

  const submitReview = async () => {

    if(!textAreaChanged){
      alert('下書きをそのままでは投稿できません。下書きを参考にしてクチコミをお願いします！');
      return
    }

    setIsSubmitting(true);
    setPopupMessage('レビューを投稿しています...');
    setShowPopup(true);

    try {
      if (docId) {
        const sendResponse = await reviewSend(docId, ratings, review);

        if (sendResponse) {
          setPopupMessage('レビューが投稿されました！\n\nコピーボタンでレビュー内容をコピーされます。\nGoogle マップにも同じ内容を投稿していただけると幸いです。');
        } else {
          setShowPopup(false);
          simulateRedirect('/thanks');
          return;
        }
      } else {
        setShowPopup(false);
        alert('レビューの投稿中にエラーが発生しました。\nもう一度お試しください。');
      }
    } catch (error) {
      setShowPopup(false);
      alert('レビューの投稿中にエラーが発生しました。\nもう一度お試しください。');
      
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    simulateRedirect(transitionUrl);
  };

  if (notFound) {
    return <h2>404 エラー: ページが見つかりません</h2>; // 404エラーメッセージ表示
  }

  return (
    <>
      {data ? (
        <div className="tw-max-w-md tw-mx-auto tw-mt-4 tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-lg">
          <div className="tw-text-sm tw-font-bold tw-mb-4 tw-text-center tw-text-gray-800 tw-border-2 tw-border-orange-500 tw-p-2">
            {data.storeName} へご来店いただきありがとうございました。レビューにご協力いただけますと幸いです。
          </div>

          {/* プルダウンの実装 */}
          <div className="tw-mb-2 tw-text-center">
            <label
              htmlFor="treatsDropdown"
              className="tw-block tw-text-sm tw-font-semibold tw-text-gray-700 tw-mb-1"
            >
              {data.treatsLabel}
            </label>
            <div className="tw-flex tw-justify-center">
              <select
                id="treatsDropdown"
                className="tw-px-4 tw-py-1 tw-border tw-border-gray-300 tw-rounded-full tw-bg-gray-200 tw-text-gray-700 tw-cursor-pointer tw-transition-colors hover:tw-bg-gray-300 focus:tw-outline-none tw-text-center"
                onChange={(e) => handleTreatChange(e.target.value)}
              >
                <option value="" disabled selected>
                  選択してください
                </option>
                {treats.map((treat, idx) => (
                  <option key={idx} value={treat}>
                    {treat}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* 質問ごとのレーティング選択 */}
          {questions.map((question: Question) => (
            <div key={question.id} className="tw-mb-2">
              <h3 className="tw-text-sm tw-font-semibold tw-mb-1 tw-text-gray-700 tw-text-center">
                {question.text}
              </h3>
              <div className="tw-flex tw-justify-center tw-space-x-2">
                {question.options.map((option, idx: number) => (
                  <button
                    key={idx}
                    onClick={() => handleRatingChange(question.text, idx)}
                    className={`tw-px-3 tw-py-1 tw-rounded-full tw-cursor-pointer tw-transition-colors tw-text-sm ${ratings[question.text] === idx
                        ? idx === 2
                          ? 'tw-bg-blue-500 tw-text-white'
                          : idx === 1
                            ? 'tw-bg-green-500 tw-text-white'
                            : 'tw-bg-red-500 tw-text-white'
                        : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300'
                      }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          ))}

          {/* レビュー自動生成と入力エリア */}
          <div className="tw-mb-4">
            <button
              onClick={handleGenerateReview}
              disabled={isGenerating}
              className={`tw-w-full tw-mb-2 tw-px-4 tw-py-2 tw-text-white tw-rounded-full tw-text-sm tw-transition-colors tw-flex tw-items-center tw-justify-center ${isGenerating
                  ? 'tw-bg-gray-400 tw-cursor-not-allowed'
                  : 'tw-bg-blue-500 hover:tw-bg-blue-600'
                }`}
            >
              {isGenerating ? (
                <span className="tw-flex tw-items-center">
                  <svg
                    className="tw-animate-spin -tw-ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="tw-opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="tw-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  生成中...
                </span>
              ) : (
                <>
                  <Sparkles className="tw-mr-2" size={18} />
                  <span>下書きを作ってもらう</span>
                </>
              )}
            </button>
            <textarea
              ref={reviewTextAreaRef}
              value={review}
              onChange={handleReviewChange}
              placeholder="ご意見をお聞かせください"
              className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg tw-text-sm focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-transparent"
              rows={4}
            />
          </div>

          {/* レビュー投稿ボタン */}
          <button
            onClick={submitReview}
            disabled={isSubmitDisabled() || isSubmitting}
            className={`tw-w-full tw-px-4 tw-py-2 tw-text-white tw-rounded-full tw-text-sm tw-transition-colors tw-flex tw-items-center tw-justify-center ${isSubmitting || isSubmitDisabled()
                ? 'tw-bg-gray-400 tw-cursor-not-allowed'
                : 'tw-bg-green-500 hover:tw-bg-green-600'
              }`}
          >
            {isSubmitting ? (
              '投稿中...'
            ) : (
              <>
                <Send className="tw-mr-2" size={18} />
                <span>レビューを投稿</span>
              </>
            )}
          </button>

          {/* ポップアップメッセージ */}
          {showPopup && (
            <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center">
              <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-lg tw-max-w-sm tw-w-full">
                <p className="tw-text-center tw-mb-4 tw-whitespace-pre-line">
                  {popupMessage}
                </p>
                {!isSubmitting && (
                  <>
                    {/* コピーボタンを追加 */}
                    <button
                      onClick={handleCopy}
                      className={`tw-w-full tw-px-4 tw-py-2 ${isCopied ? 'tw-bg-gray-400' : 'tw-bg-green-500'
                        } tw-text-white tw-rounded-full tw-text-sm tw-mb-2 tw-transition-colors`}
                      disabled={isCopied}
                    >
                      {isCopied ? 'コピーしました' : 'コピー'}
                    </button>

                    {/* OKボタン */}
                    <button
                      onClick={handleClosePopup}
                      className="tw-w-full tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-full tw-text-sm hover:tw-bg-blue-600 tw-transition-colors"
                    >
                      OK
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <p>読込中…</p>
        </>
      )}
    </>
  );
};

export default ReviewForm;
