// 領収書ダウンロードページ

import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver'; // ファイルをダウンロードするためのライブラリ
import { db, auth } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { stripeGetPaymentHistory, recipientUpdate } from './api';
import './css/EditMemberInfo.css';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const EditMemberInfo = () => {
  const [userType, setUserType] = useState('個人');
  const [name, setName] = useState('');
  const [dbuserType, setDBUserType] = useState('個人');
  const [dbname, setDBName] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null);
  const [paymentHistory, setPaymentHistory] = useState<any | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false); // ロード状態

  useEffect(() => {
    const fetchUserData = async () => {
      setIsSubmitting(true);
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          const idToken = await user.getIdToken();
          const paymentHistory = await stripeGetPaymentHistory(idToken);
          setPaymentHistory(paymentHistory);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserType(data?.userType || '個人');
            setName(data?.recipient || '');
            setDBUserType(data?.userType || '個人');
            setDBName(data?.recipient || '');
          }
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsSubmitting(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedInvoiceId = event.target.value;
    const matchingInvoice = paymentHistory?.invoices.find((invoice: any) => invoice.id === selectedInvoiceId);
    setSelectedInvoice(matchingInvoice || null);
  };

  const invoicePeriodText = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const formatDate = (date: Date) =>
      `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;

    return `${formatDate(startDate)} ～ ${formatDate(endDate)}`;
  };

  // 当日日付
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので +1
    const day = String(today.getDate()).padStart(2, '0'); // 日付が1桁の場合は0埋め

    return `${year}年${month}月${day}日`;
  };

  // 請求日日付を得る
  const formatDateAndTwoWeeksLater = (isoDateString) => {
    // ISO形式の文字列をDateオブジェクトに変換
    const date = new Date(isoDateString);

    // 年、月、日を取得し、フォーマット
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため +1
    const day = String(date.getDate()).padStart(2, '0'); // 日付が1桁の場合は0埋め

    const formattedDate = `${year}年${month}月${day}日`;

    // 2週間後の日付を計算
    const twoWeeksLater = new Date(date);
    twoWeeksLater.setDate(twoWeeksLater.getDate() + 14);

    const twoWeeksLaterYear = twoWeeksLater.getFullYear();
    const twoWeeksLaterMonth = String(twoWeeksLater.getMonth() + 1).padStart(2, '0');
    const twoWeeksLaterDay = String(twoWeeksLater.getDate()).padStart(2, '0');

    const formattedTwoWeeksLaterDate = `${twoWeeksLaterYear}年${twoWeeksLaterMonth}月${twoWeeksLaterDay}日`;

    return {
      formattedDate,
      formattedTwoWeeksLaterDate
    };
  };

  const generateAndDownloadPDF = async () => {
    if (!selectedInvoice) return;

    const honorific = userType === '個人' ? '様' : '御中';
    const formatDate = formatDateAndTwoWeeksLater(selectedInvoice.period_start)
    const invoice_kikan = invoicePeriodText(selectedInvoice.period_start, selectedInvoice.period_end)
    const user = auth.currentUser;

    setIsSubmitting(true);

    if (user && (dbuserType != userType || dbname != name)) {
      const idToken = await user.getIdToken();
      await recipientUpdate(idToken, userType, name);
      setIsSubmitting(false);
    }

    // PDFの内容となるHTMLを生成
    const pdfContent = `
      <div style="font-family: Arial, sans-serif; font-size: 12px; padding: 20px; max-width: 800px; width: 100%;">
        <h2 style="text-align: center; font-size: 18px;">領 収 書</h2>

        <div style="display: flex; justify-content: space-between; width: 100%;">
          <!-- 左カラム -->
          <div>
            <p>宛名: ${name} ${honorific}</p>
            <p>下記、正に領収いたしました。</p>
            <p>件名: クチコミフエール</p>
            <p>請求日: ${formatDate.formattedDate}</p>
            <p>支払期限: ${formatDate.formattedTwoWeeksLaterDate}</p>
            <p>入金日: ${formatDate.formattedDate}</p>
          </div>

          <!-- 右カラム -->
          <div style="text-align: right;">
            <p>No:${selectedInvoice.invoice_number}</p>
            <p>発行日: ${getFormattedDate()}</p>
            <p>ノマライズ</p>
            <p>〒651-0084</p>
            <p>兵庫県神戸市中央区磯辺通1丁目1番18号</p>
            <p>カサベラ国際プラザビル707号室</p>
            <p>TEL: 078-202-5526</p>
          </div>
        </div>

        <hr style="margin: 20px 0;" />

        <!-- 合計金額 -->
        <div style="border: 1px solid #000; padding: 10px; text-align: center; font-size: 16px;">
          合計: 7,980 円 (税込)
        </div>

        <!-- テーブル -->
        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
            <tr>
              <th style="border: 1px solid #000; padding: 8px; background-color: #000; color: #fff;">摘要</th>
              <th style="border: 1px solid #000; padding: 8px; background-color: #000; color: #fff;">数量</th>
              <th style="border: 1px solid #000; padding: 8px; background-color: #000; color: #fff;">単位</th>
              <th style="border: 1px solid #000; padding: 8px; background-color: #000; color: #fff;">単価</th>
              <th style="border: 1px solid #000; padding: 8px; background-color: #000; color: #fff;">金額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #000; padding: 8px;">クチコミフエール1ヵ月利用料</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: center;">1</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: center;">式</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: right;">7,254</td>
              <td style="border: 1px solid #000; padding: 8px; text-align: right;">7,254</td>
            </tr>
          </tbody>
        </table>

        <!-- 小計・税 -->
        <div style="text-align: right; margin-top: 10px;">
          <p>小計: 7,254 円</p>
          <p>消費税: 726 円</p>
          <p><strong>合計: 7,980 円</strong></p>
        </div>

        <!-- 備考 -->
        <div style="margin-top: 20px;">
          <p><strong>備考</strong></p>
          <p>契約期間: ${invoice_kikan}</p>
        </div>
      </div>
    `;

    // DOM要素を作成し、一時的にレンダリング
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = pdfContent;
    document.body.appendChild(tempDiv);

    // A4サイズの横幅 (210mm) を基準にした解像度でキャプチャ
    const canvas = await html2canvas(tempDiv, {
      width: 794, // A4横幅に対応するpxサイズ
      scale: 2, // 高解像度でキャプチャするためのスケール
      useCORS: true,
    });
    const imgData = canvas.toDataURL('image/png');

    // jsPDFでPDFを生成
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    // A4サイズにピッタリと画像を挿入
    const imgWidth = 210; // A4サイズの幅 (mm)
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // アスペクト比を維持して高さを計算

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

    // PDFをBlobとして保存し、FileSaverを使用してダウンロード
    const blob = pdf.output('blob');
    saveAs(blob, 'receipt.pdf');

    // 一時的に追加したDOM要素を削除
    document.body.removeChild(tempDiv);
    setIsSubmitting(false);
  };

  return (
    <>
    <div className="form-container">
      <h2>会員情報変更</h2>
      <form>
        <table className="form-table">
          <tbody>
            <tr>
              <td className="lefttd"><label>区分:</label></td>
              <td>
                <div className="radio-group">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="individual"
                      value="個人"
                      checked={userType === '個人'}
                      onChange={() => setUserType('個人')}
                    />
                    <label htmlFor="individual">個人</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="corporate"
                      value="法人"
                      checked={userType === '法人'}
                      onChange={() => setUserType('法人')}
                    />
                    <label htmlFor="corporate">法人</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="lefttd"><label htmlFor="name">名前:</label></td>
              <td>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </td>
            </tr>

            <tr>
              <td className="lefttd"><label htmlFor="subscription-period">対象期間:</label></td>
              <td>
                {paymentHistory ? (
                  <select
                    id="subscription-period"
                    onChange={handleSelectChange}
                    value={selectedInvoice?.id || ''}
                  >
                    <option value="" disabled>期間を選択</option>
                    {paymentHistory.invoices.map((invoice: any) => (
                      <option key={invoice.id} value={invoice.id}>
                        {invoicePeriodText(invoice.period_start, invoice.period_end)}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>データを読み込み中...</p>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {/* PDF生成ボタン */}
        <div className="invoice-dl-btn">
          <button className="downloadButton" type="button" onClick={generateAndDownloadPDF} disabled={!selectedInvoice}>
            領収書をダウンロード
          </button>
        </div>
      </form>
    </div>

    {isSubmitting && (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    )}
    </>
  );
};

export default EditMemberInfo;
