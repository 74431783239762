import React, { useState } from 'react';
import './css/ContactForm.css'; // スタイルシートをインポート
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート

import { deleteUser , contactSend, kuchikomiCardBuy } from './api';

const ContactForm: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isTextareaDisabled, setIsTextareaDisabled] = useState<boolean>(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // ローディング状態を管理
  const [showDeleteDialog ,setShowDeleteDialog] = useState(false)

  const navigate = useNavigate(); // useNavigateを取得

  const precautions = `
  ■■退会する前の注意事項■■
  ※必ずお読みください。

  --- サブスクリプションについて ---
  退会処理をもってサービスはご利用いただけなくなります。
  その際、有効なサブスクリプションが残っている場合でも返金は承り致しかねますのでご了承ください。
  ※サブスクリプションのみの解約であれば、契約管理画面より受付しております。
  
  --- 会員情報について ---
  レビュー履歴や契約者様のメールアドレス等、退会処理をもって全てのデータは破棄されます。
  いかなる場合でもデータの復元はできませんのでご了承ください。
  `

  const contacttips = `
  お問い合わせ・ご要望をここに入力してください。
  `

  const kuchikomicardtips = `
  クチコミカードの購入にあたり下記事項を入力し回答ください。

  発注枚数：
  お届け先：
  宛名：
  電話番号：
  その他事項：

  フォーム送信後、
  回答内容を元に見積もりをお送りいたします。

  ※回答いただいた内容は商品発送のみに利用されます。
  弊社のデータベースにお客様の情報が保存されることはなく、
  注文後、回答データは破棄されます。
  `

  const templates: { [key: string]: string } = {
    inquiry: contacttips,
    kuchikomicard: kuchikomicardtips,
    withdrawal: precautions
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedTemplate(value);

    if (value === 'withdrawal') {
      setMessage(templates[value]);
      setIsTextareaDisabled(false);
      setIsSubmitDisabled(false); // 退会ボタンを有効にする
    } else if (value) {
      setMessage(templates[value]);
      setIsTextareaDisabled(false);
      setIsSubmitDisabled(false);
    } else {
      setMessage('');
      setIsTextareaDisabled(true);
      setIsSubmitDisabled(true);
    }
  };

  const confirmDelete = () => {
    setShowDeleteDialog(true); // ポップアップ表示
  };

  const cancelDelete = () => {
    setShowDeleteDialog(false); // ポップアップ表示
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);
    setIsSubmitDisabled(value.trim() === '');
  };

  const handleDelete = async () => {
    
    const user = auth.currentUser;
    setIsSubmitting(true); // ローディング開始
    if (user) {
      try {
        const idToken = await user.getIdToken(); // Firebase Authentication トークン取得
        const response = await deleteUser(idToken);

        if (response.status === 200) {
          await auth.signOut(); // ユーザーをサインアウト
          cancelDelete();
          setIsSubmitting(false);
          navigate('/quit', { replace: true }); // 退会処理成功後、quitページに遷移
        }else{
          cancelDelete();
          alert('処理に失敗しました');
        }
      } catch (error) {
        cancelDelete();
        alert('処理に失敗しました');
      } finally {
        setIsSubmitting(false); // ローディング終了
      }
    }
  };

  // お問い合わせ送信
  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    if (!message.trim()) return;

    // 退会処理はhandleDeleteによって実施
    setIsSubmitting(true); // ローディング開始
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken(); 
        if(selectedTemplate === 'withdrawal'){
          // 退会処理は別でハンドリングするためなにもしない
        }else if(selectedTemplate === 'inquiry') {
          // お問い合わせ
          await contactSend(idToken, message);
          alert('お問い合わせが送信されました。');
        } else if (selectedTemplate === 'kuchikomicard') {
          // お問い合わせ
          await kuchikomiCardBuy(idToken, message);
          alert('お問い合わせが送信されました。');
        } else{
          setIsSubmitting(true); // ローディング開始
          // お問い合わせ送信処理を実行する
          setIsSubmitting(false); // ローディング終了
          alert('お問い合わせが送信されました。');
        }

        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1000ミリ秒 = 1秒

      }
    }catch (error){
      console.log(error)
    }finally{
      setIsSubmitting(false);
    }

  };

  return (
    <div className="contact-container">
      <h1 className="contact-header">お問い合わせ</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label className="contact-label">
          テンプレートを選択してください:
          <select
            className="contact-select"
            value={selectedTemplate}
            onChange={handleSelectChange}
          >
            <option value="">選択してください</option>
            <option value="inquiry">お問い合わせ・ご要望</option>
            <option value="kuchikomicard">クチコミカードの購入希望</option>
            <option value="withdrawal">退会したい</option>
          </select>
        </label>
        <label className="contact-label">
          内容:
          <textarea
            className="contact-textarea"
            value={message}
            onChange={handleTextareaChange}
            disabled={isTextareaDisabled}
            readOnly={selectedTemplate === 'withdrawal'} 
            placeholder="テンプレートを選択してください"
          />
        </label>

        {/* 退会と送信ボタンのスイッチング */}
        {selectedTemplate === 'withdrawal' ? (
          <button
            className="contact-submit-button"
            disabled={isSubmitDisabled}
            onClick={confirmDelete}
          >
            退会する
          </button>
        ) : (
          <button
            className="contact-submit-button"
            type="submit"
            disabled={isSubmitDisabled}
          >
            送信
          </button>
        )}
      </form>

      {/* 退会確認ダイアログ */}
      {
        showDeleteDialog && (
          <div className="delete-dialog">
            <div className="delete-dialog-content">
              <div>
              <p>本当に退会しますか？</p>
              <p>※退会処理完了後、いかなる場合でもデータの復元はできませんのでご了承ください。</p>
              </div>
              <button onClick={handleDelete} className="btn-confirm">
                退会する
              </button>
              <button onClick={cancelDelete} className="btn-cancel">
                退会しない
              </button>
            </div>
          </div>
        )
      }

      {/* ローディング中はくるくる回るアニメーションを表示 */}
      {isSubmitting && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

    </div>

  );
};

export default ContactForm;
