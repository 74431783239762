import React, { useEffect, useState } from 'react';
import { useNavigate , Link} from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { db , auth } from './firebase';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false); // ローディング状態を管理

    useEffect(() => {
        // Firebase認証状態の変更を監視
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // ユーザーがログインしている場合、マイページにリダイレクト
                navigate('/mypage');
            }
        });

        // コンポーネントのアンマウント時にリスナーを解除
        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsSubmitting(true);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await userCredential.user?.getIdToken();
            
        } catch (error) {
            console.error('ログインエラー:', error);
            alert('ログインに失敗しました。');
            setIsSubmitting(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="tw-min-h-screen tw-bg-gray-100 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-py-8">
            <div className="tw-w-full tw-max-w-md tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-6 sm:tw-p-8 tw-text-center">
                <h1 className="tw-text-xl sm:tw-text-2xl tw-font-bold tw-text-gray-800 tw-mb-4">
                    クチコミフエール：ログイン
                </h1>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="メールアドレス"
                        required
                        className="tw-w-full tw-px-4 tw-py-2 tw-mb-4 tw-border tw-rounded-lg"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="パスワード"
                        required
                        className="tw-w-full tw-px-4 tw-py-2 tw-mb-4 tw-border tw-rounded-lg"
                    />
                    <button type="submit" className="tw-w-full tw-bg-blue-500 tw-text-white tw-py-2 tw-rounded-lg">
                        ログイン
                    </button>
                </form>
                {/* パスワードリセットへのリンクを追加 */}
                <div className="tw-mt-4">
                    <Link to="/password-reset" className="tw-text-blue-500 hover:tw-underline">
                        パスワードをお忘れですか？
                    </Link>
                </div>
            </div>
            {/* ロード画面 */}
            {isSubmitting && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

export default LoginPage;
