// firebase.ts
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDUxwjItBMjwkHbdX6kQWe7m2zhoPkGqxM",
    authDomain: "review-e880a.firebaseapp.com",
    databaseURL: "https://review-e880a-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "review-e880a",
    storageBucket: "review-e880a.appspot.com",
    messagingSenderId: "553606982049",
    appId: "1:553606982049:web:8eaeef4fcf9d41bcefc3e0",
    measurementId: "G-DMDNHYDKJ1"
  };

// Firebaseアプリの初期化
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export const STRIPE_TEST_PUBLIC_KEY = 'pk_test_51Puosi01YgfeVWAKgLIWz8i2PUg2ELyOEMyGEXHLHNOGnTdNSLb0V4QhRY9qHumLGCawkBce8CzSPQKgaGNlfBh300Ya1ov2ak';
export const STRIPE_LIVE_PUBLIC_KEY = 'pk_live_51Puosi01YgfeVWAKaTVrhblayp15S94tioOPrWYYFCnLXfG4k9YdQGfxbvo8zXe0aRXaH9e54GjP4RqMZGKf4lg400aj1IzvVk';