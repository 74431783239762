import React, { useEffect, useState } from 'react';
import { loadStripe, StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import {
    Elements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import './css/PaymentForm.css';

import { db, auth,  STRIPE_LIVE_PUBLIC_KEY } from './firebase';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { cardregist, stripeCreateSubscription } from './api'

import Alert from './Alert'; // 先ほど作成したAlertコンポーネントをインポート

import visaLogo from './image/visa.png';
import jcbLogo from './image/jcb.png';
import masterLogo from './image/master.png';
import amexLogo from './image/amex.png';
import dinersLogo from './image/diners.png';

const stripePromise = loadStripe(STRIPE_LIVE_PUBLIC_KEY); // 公開可能キー

const ElementReCreate = () => {

    // --- カード情報 ---
  const [exp_month, setExp_month] = useState<string | null>(null); //
  const [exp_year, setExp_year] = useState<string | null>(null); //
  const [last4, setLast4] = useState<string | null>(null); //
  const [stripeId, setStripeId] = useState<string | null>(null);

    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false); // ロード状態
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [cardBrand, setCardBrand] = useState(''); // カードブランドの状態
    const [cardholderName, setCardholderName] = useState(''); // 名義人の状態
    const [alternateBrand, setAlternateBrand] = useState(true); // JCBとDinersの交互表示用
    const [isSubmitted, setIsSubmitted] = useState(false); // フォーム送信状態

    const [isNewCard, setIsNewCard] = useState(false); // デフォルトは既存カードが選択されている

    // サブスク期限
    const [subscriptionEndDate, setSubscriptionEndDate] = useState<string | null>(null);

    const [status, setStatus] = useState<string | null>(null); //

    // チェックボックスの状態
    const [isChecked, setIsChecked] = useState(false);

    // サブスクリプション再作成
    const reCreateSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        if (!isChecked) {
            setErrorMessage('利用規約に同意してください');
            setIsSubmitting(false);
            return;
        }
        
        // ------  (サブスクリプション再作成)  --------
        try {
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken();
                const response = await stripeCreateSubscription(idToken);
                setIsSubmitted(true); // 送信完了画面へ切り替えフラグ
            } else {
                setErrorMessage('ユーザーがログインしていません。');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('予期せぬエラーが発生しました。');
        } finally {
            setIsSubmitting(false);
        }
    };

    // サブスクリプション再作成 & カード情報更新
    const reCreateAndPaymentSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        if (!isChecked) {
            setErrorMessage('利用規約に同意してください');
            setIsSubmitting(false);
            return;
        }
        if (!stripe || !elements) {
            setErrorMessage('読込エラー発生');
            setIsSubmitting(false);
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
            setErrorMessage('カード情報を入力してください。');
            setIsSubmitting(false);
            return;
        }

        if (cardholderName === '') {
            setErrorMessage('名義人は必須項目です。');
            setIsSubmitting(false);
            return;
        }

        // カード情報をトークン化
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                name: cardholderName, // 名義人情報を送信
            },
        });

        if (error) {
            setErrorMessage('決済処理に失敗しました。');
            setIsSubmitting(false);
            return;
        }

        // ------ PaymentMethodをfirestoreに登録 (サブスクリプション作成)  --------
        try {
            const user = auth.currentUser;
            if (user) {
                const response = await cardregist(user.uid, paymentMethod.id);
                setIsSubmitted(true); // 送信完了画面へ切り替えフラグ

                setTimeout(() => {
                    window.location.reload();
                }, 10000); // 1000ミリ秒 = 1秒
            } else {
                setErrorMessage('ユーザーがログインしていません。');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };


    //---------------- 一か月後の日付 -------------------
    const getNextMonthDate = (): string => {
        const currentDate = new Date();

        let startDate: Date;
        startDate = currentDate;

        // 1か月後の日付を計算
        const nextMonthDate = new Date(startDate.setMonth(startDate.getMonth() + 1));

        // 年月日をフォーマット
        const year = nextMonthDate.getFullYear();
        const month = String(nextMonthDate.getMonth() + 1).padStart(2, '0'); // 月は0始まりなので+1
        const day = String(nextMonthDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // カード番号入力の変更イベントを処理する関数
    const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
        const brand = event.brand || 'unknown';
        setCardBrand(brand); // カードブランドを更新
    };

    const formatSubscriptionEndDate = (timestamp: Timestamp | number): string => {

        let endDate: Date;

        if (timestamp instanceof Timestamp) {
        endDate = timestamp.toDate();
        } else {
        endDate = new Date(timestamp * 1000);
        }

        // endDateを "YYYY-MM-DD" のフォーマットに変換
        const year = endDate.getFullYear();
        const month = ('0' + (endDate.getMonth() + 1)).slice(-2); // 月は0ベースのため +1
        const day = ('0' + endDate.getDate()).slice(-2);

        // 現在の日付との差を計算
        const today = new Date();
        const diffTime = endDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 差を「日数」に変換

        // フォーマットされた日付と「あと何日」を返す
        return `${year}-${month}-${day}`;
    };

    // カードブランドの表示ロジック
    const renderCardBrand = () => {
        if (cardBrand === 'unknown' || !cardBrand) {
            return (
                <>
                    <img src={visaLogo} alt="Visa" className="brand-logo" />
                    <img src={masterLogo} alt="MasterCard" className="brand-logo" />
                    <img src={amexLogo} alt="Amex" className="brand-logo" />
                    <img src={alternateBrand ? jcbLogo : dinersLogo} alt={alternateBrand ? "JCB" : "Diners"} className="brand-logo" />
                </>
            );
        }
        switch (cardBrand) {
            case 'visa':
                return <img src={visaLogo} alt="Visa" className="brand-logo" />;
            case 'mastercard':
                return <img src={masterLogo} alt="MasterCard" className="brand-logo" />;
            case 'amex':
                return <img src={amexLogo} alt="Amex" className="brand-logo" />;
            case 'jcb':
                return <img src={jcbLogo} alt="JCB" className="brand-logo" />;
            case 'diners':
                return <img src={dinersLogo} alt="Diners" className="brand-logo" />;
            default:
                return 'カードブランド不明';
        }
    };

    useEffect(() => {
        const user = auth.currentUser;

        if (!user) return;  // ユーザーが存在しない場合は早期リターン

        const uid = user.uid;

        // Firestoreのユーザードキュメントのリスナーを設定
        const unsubscribe = onSnapshot(doc(db, 'users', uid), (docSnapshot) => {
        if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setStatus(userData.status);
            setSubscriptionEndDate(formatSubscriptionEndDate(userData.subscriptionEndDate));
            setLast4(userData.cardLast4);
            setExp_month(userData.cardExpMonth);
            setExp_year(userData.cardExpYear);
        }
        }, (error) => {
        console.error("Firestoreのリアルタイム取得エラー:", error);
        });

        // コンポーネントがアンマウントされた時にリスナーを解除
        return () => {
        unsubscribe();
        };
    }, []);  // 初回マウント時のみ実行

    return(
        <>
        {!isSubmitted ? (

            <>
            {last4 ? (
                <>
                <div className="subscriber-container">
                    <div className="payment-card-choice">
                        <label>
                        <input
                            type="radio"
                            value="existing"
                            checked={!isNewCard}
                            onChange={() => setIsNewCard(false)} // 既存カードを選択
                        />
                        既存のカードで決済する (**** **** **** {last4} {exp_month}/{exp_year})
                        </label>
                        <label>
                        <input
                            type="radio"
                            value="new"
                            checked={isNewCard}
                            onChange={() => setIsNewCard(true)} // 新しいカードを選択
                        />
                        新しいカードで決済する
                        </label>
                    </div>

                    {!isNewCard ? (
                        <>
                        <div className="payment-new-card">
                            <div className="payment-explan">
                                <p>弊社では決済にStripeAPIを利用しています。</p>
                                <p>カード情報は弊社では保管せずに、強固なセキュリティを持つStripeで安全に管理されます。</p>
                            </div>
                            <form onSubmit={reCreateSubmit}>

                                {/* チェックボックス */}
                                <div className='payment-kiyaku'>
                                    <label>
                                        <input
                                            className='payment-kiyaku-check'
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) => setIsChecked(e.target.checked)}
                                        />
                                        <a className="payment-kiyaku-link" href="https://kuchikomi-fueru.com/terms/" target="_blank" rel="noopener noreferrer">
                                            利用規約
                                        </a>
                                        に同意する
                                    </label>
                                </div>

                                <button type="submit" className="payment-submit-button" disabled={isSubmitting}>
                                    決済する
                                </button>

                                {errorMessage && <div className="payment-error-message">{errorMessage}</div>}
                                {successMessage && <div className="payment-success-message">{successMessage}</div>}
                            </form>
                        </div>
                        </>
                    ):(
                        <>
                        <div className="payment-new-card">
                            <div className="payment-explan">
                                <p>弊社では決済にStripeAPIを利用しています。</p>
                                <p>カード情報は弊社では保管せずに、強固なセキュリティを持つStripeで安全に管理されます。</p>
                            </div>
                            <form onSubmit={reCreateAndPaymentSubmit}>
                                <div className="payment-input-group">
                                    <label htmlFor="card-number" className="payment-input-label">カード番号:</label>
                                    <div className="card-number-container">
                                        <CardNumberElement
                                            id="card-number"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#424770',
                                                        '::placeholder': { color: '#aab7c4' },
                                                    },
                                                    invalid: { color: '#9e2146' },
                                                },
                                            }}
                                            className="payment-card-element"
                                            onChange={handleCardNumberChange} // カード番号入力の変更を監視
                                        />
                                        <div className="card-brand">
                                            {renderCardBrand()} {/* カードブランドを表示 */}
                                        </div>
                                    </div>
                                </div>

                                {/* 有効期限とCVCを横に並べる */}
                                <div className="payment-input-row">
                                    <div className="payment-input-group">
                                        <label htmlFor="card-expiry" className="payment-input-label">有効期限:</label>
                                        <CardExpiryElement
                                            id="card-expiry"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#424770',
                                                        '::placeholder': { color: '#aab7c4' },
                                                    },
                                                    invalid: { color: '#9e2146' },
                                                },
                                            }}
                                            className="payment-card-element"
                                        />
                                    </div>

                                    <div className="payment-input-group">
                                        <label htmlFor="card-cvc" className="payment-input-label">CVC:</label>
                                        <CardCvcElement
                                            id="card-cvc"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#424770',
                                                        '::placeholder': { color: '#aab7c4' },
                                                    },
                                                    invalid: { color: '#9e2146' },
                                                },
                                            }}
                                            className="payment-card-element"
                                        />
                                    </div>
                                </div>

                                <div className="payment-input-group">
                                    <label htmlFor="cardholder-name" className="payment-input-label">名義人:</label>
                                    <input
                                        id="cardholder-name"
                                        type="text"
                                        value={cardholderName}
                                        onChange={(e) => setCardholderName(e.target.value)} // 名義人の更新
                                        className="payment-input payment-card-element"
                                    />
                                </div>

                                <div className="payment-price-table">
                                    <table className="price-table">
                                        <thead>
                                        <tr className="price-table-header">
                                            <th>期間</th>
                                            <th>有効期限</th>
                                            <th>価格</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="price-table-row">
                                            <td>1ヵ月</td>
                                            <td>{getNextMonthDate()}</td>
                                            <td>7,980円</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* チェックボックス */}
                                <div className='payment-kiyaku'>
                                    <label>
                                        <input
                                            className='payment-kiyaku-check'
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) => setIsChecked(e.target.checked)}
                                        />
                                        <a className="payment-kiyaku-link" href="https://kuchikomi-fueru.com/terms/" target="_blank" rel="noopener noreferrer">
                                            利用規約
                                        </a>
                                        に同意する
                                    </label>
                                </div>

                                <button type="submit" className="payment-submit-button" disabled={isSubmitting}>
                                    決済する
                                </button>

                                {errorMessage && <div className="payment-error-message">{errorMessage}</div>}
                                {successMessage && <div className="payment-success-message">{successMessage}</div>}
                            </form>
                        </div>
                        </>
                    )}
                </div>
                </>
            ) : (
                <>
                <div className="subscriber-container">
                    <div className="payment-explan">
                        <p>弊社では決済にStripeAPIを利用しています。</p>
                        <p>カード情報は弊社では保管せずに、強固なセキュリティを持つStripeで安全に管理されます。</p>
                    </div>
                        <form onSubmit={reCreateAndPaymentSubmit}>
                            <div className="payment-input-group">
                                <label htmlFor="card-number" className="payment-input-label">カード番号:</label>
                                <div className="card-number-container">
                                    <CardNumberElement
                                        id="card-number"
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': { color: '#aab7c4' },
                                                },
                                                invalid: { color: '#9e2146' },
                                            },
                                        }}
                                        className="payment-card-element"
                                        onChange={handleCardNumberChange} // カード番号入力の変更を監視
                                    />
                                    <div className="card-brand">
                                        {renderCardBrand()} {/* カードブランドを表示 */}
                                    </div>
                                </div>
                            </div>

                            {/* 有効期限とCVCを横に並べる */}
                            <div className="payment-input-row">
                                <div className="payment-input-group">
                                    <label htmlFor="card-expiry" className="payment-input-label">有効期限:</label>
                                    <CardExpiryElement
                                        id="card-expiry"
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': { color: '#aab7c4' },
                                                },
                                                invalid: { color: '#9e2146' },
                                            },
                                        }}
                                        className="payment-card-element"
                                    />
                                </div>

                                <div className="payment-input-group">
                                    <label htmlFor="card-cvc" className="payment-input-label">CVC:</label>
                                    <CardCvcElement
                                        id="card-cvc"
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    color: '#424770',
                                                    '::placeholder': { color: '#aab7c4' },
                                                },
                                                invalid: { color: '#9e2146' },
                                            },
                                        }}
                                        className="payment-card-element"
                                    />
                                </div>
                            </div>

                            <div className="payment-input-group">
                                <label htmlFor="cardholder-name" className="payment-input-label">名義人:</label>
                                <input
                                    id="cardholder-name"
                                    type="text"
                                    value={cardholderName}
                                    onChange={(e) => setCardholderName(e.target.value)} // 名義人の更新
                                    className="payment-input payment-card-element"
                                />
                            </div>

                            <div className="payment-price-table">
                                <table className="price-table">
                                    <thead>
                                    <tr className="price-table-header">
                                        <th>期間</th>
                                        <th>有効期限</th>
                                        <th>価格</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="price-table-row">
                                        <td>1ヵ月</td>
                                        <td>{getNextMonthDate()}</td>
                                        <td>7,980円</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* チェックボックス */}
                            <div className='payment-kiyaku'>
                                <label>
                                    <input
                                        className='payment-kiyaku-check'
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) => setIsChecked(e.target.checked)}
                                    />
                                    <a className="payment-kiyaku-link" href="https://kuchikomi-fueru.com/terms/" target="_blank" rel="noopener noreferrer">
                                        利用規約
                                    </a>
                                    に同意する
                                </label>
                            </div>

                            <button type="submit" className="payment-submit-button" disabled={isSubmitting}>
                                決済する
                            </button>

                            {errorMessage && <div className="payment-error-message">{errorMessage}</div>}
                            {successMessage && <div className="payment-success-message">{successMessage}</div>}
                        </form>
                </div>
                </>
            )}

            {isSubmitting && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            </>

        ) : (
            <>
            {status === 'active' ? (
                <>
                <div>
                <p>決済が完了しました！クチコミフエールのご利用が開始されました。</p>
                <p>次回の請求は [{subscriptionEndDate}] です。ご質問がある場合は、いつでもサポートにお問い合わせください。</p>
                                    <p>※このページは自動的に切り替わります。切り替わらない場合は、ページを再読み込みしてください。</p>
                </div>
                </>
            ) : status === 'trialing' ? (
                <>
                <div>
                <p>決済が完了しました！クチコミフエールのご利用が開始されました。</p>
                <p>次回の請求は [{subscriptionEndDate}] です。ご質問がある場合は、いつでもサポートにお問い合わせください。</p>
                                        <p>※このページは自動的に切り替わります。切り替わらない場合は、ページを再読み込みしてください。</p>
                </div>
                </>
            ) : status === 'incomplete' ? (
                <div>支払いが未完了です。お支払いを確認してください。</div>
            ) : status === 'unpaid' ? (
                <div>支払いが失敗しました。お支払い情報を更新してください。</div>
            ) : status === 'past_due' ? (
                <div>お支払い期限が過ぎています。</div>
            ) : (
                <div>読込中…</div>
            )}
            </>
        )}
        </>
    )
};

const SubScribReCreate = () => (
    <Elements stripe={stripePromise}>
        <ElementReCreate />
    </Elements>
);

export default SubScribReCreate;