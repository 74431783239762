import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';

const PasswordResetPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const actionCodeSettings = {
        // パスワードリセットリンクの遷移先をカスタムパスワードリセットページに設定
        url: 'https://service.kuchikomi-fueru.com/password-reset-form/',
        handleCodeInApp: false, // ウェブで処理する場合はfalse
        // dynamicLinkDomain: 'yourapp.page.link', // Dynamic Linksを使用する場合
    };

    const handlePasswordReset = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('パスワードリセット用のメールを送信しました。ご確認ください。');
        } catch (error) {
            console.error('パスワードリセットエラー:', error);
            setMessage('パスワードリセットに失敗しました。メールアドレスをご確認ください。');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="tw-min-h-screen tw-bg-gray-100 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-py-8">
            <div className="tw-w-full tw-max-w-md tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-6 sm:tw-p-8 tw-text-center">
                <h1 className="tw-text-xl sm:tw-text-2xl tw-font-bold tw-text-gray-800 tw-mb-4">
                    パスワードリセット
                </h1>
                {message ? (
                    <p className="tw-mb-4 tw-text-green-500">{message}</p>
                ) : (
                    <form onSubmit={handlePasswordReset}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="メールアドレス"
                            required
                            className="tw-w-full tw-px-4 tw-py-2 tw-mb-4 tw-border tw-rounded-lg"
                        />
                        <button
                            type="submit"
                            className="tw-w-full tw-bg-blue-500 tw-text-white tw-py-2 tw-rounded-lg"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? '送信中...' : 'パスワードリセットメールを送信'}
                        </button>
                    </form>
                )}
                <div className="tw-mt-4">
                    <button
                        onClick={() => navigate('/login')}
                        className="tw-text-blue-500 hover:tw-underline"
                    >
                        ログインページに戻る
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetPage;
