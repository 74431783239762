import React, { useState } from "react";
import './css/FreeStartDialog.css';

const FreeStartDialog = ({ handleClose }) => {

    const [step, setStep] = useState(1); // 現在のステップを追跡

    const nextStep = () => {
        if (step < 3) {
            setStep(step + 1);
        } else {
            handleClose(); // 最終ページで閉じるボタンにする
        }
    };

    const renderContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                    <div className="tutorial-head">さっそく口コミを集めましょう！</div>
                    <div className="tutorial-point">＜＜口コミを集めるための簡単ステップ＞＞</div>
                    <div className="tutorial-step">STEP1</div>
                    <div className="tutorial-step-text">マイページからQRコードもしくはURLを保存</div>
                    <div className="tutorial-step">STEP2</div>
                    <div className="tutorial-step-text">QRコード(URL)を口コミカードや店頭POP、フォローアップメール等に埋め込み</div>
                    <div className="tutorial-step">STEP3</div>
                    <div className="tutorial-step-text">お客様に、QRコード(URL)からページに訪問いただき、口コミを投稿してもらいましょう。</div>
                    </>
                );
            case 2:
                return (
                    <>
                    
                    <div className="tutorial-point">＜＜トライアル期間について＞＞</div>
                    <div className="tutorial-step">31日間無料</div>
                    <div className="tutorial-step-text">
                        <p>クチコミフエールでは、<span className="font-red">最初の31日間を無料でお試しいただけます。</span></p>
                        <p>この期間中に、サービスが店舗に適しているかどうかをじっくりお確かめください。</p>
                        </div>
                    <div className="tutorial-step">トライアル終了後の継続利用について</div>
                    <div className="tutorial-step-text">
                        <p>トライアル期間が終了すると、<span className="font-red">サービスの利用が一時停止</span>されます。</p>
                        <p>引き続きサービスをご利用いただくためには、<span className="font-red">クレジットカード情報の登録</span>が必要です。</p>
                        <p>スムーズに継続できるよう、トライアル期間中にご登録をお済ませください。</p>
                    </div>
                    </>
                );
            case 3:
                return (
                    <>
                    <div className="tutorial-step-text">
                        <p>チュートリアルは以上になります。</p>
                        <p>不明な点などございましたら、マイページのお問い合わせよりお気軽にご連絡ください。</p>
                    </div>
                    </>
                );
                
            default:
                return null;
        }
    };

    return (
        <>
        <div className="freestart-dialog">
            <div className="freestart-dialog-content">
                {renderContent()}
                <button onClick={nextStep} className="btn-confirm">
                    {step < 3 ? "次へ" : "閉じる"}
                </button>
            </div>
        </div>
        </>
    );
};

export default FreeStartDialog;