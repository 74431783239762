import React from 'react';

const NotFoundPage: React.FC = () => {
  return (
    <div style={styles.body}>
      <div style={styles.message}>
        <h2 style={styles.h2}>404</h2>
        <h1 style={styles.h1}>ページが見つかりません</h1>
        <p style={styles.p}>
          指定されたファイルがこのウェブサイトで見つかりませんでした。URLに間違いがないか確認して、もう一度お試しください。
        </p>
      </div>
    </div>
  );
};

const styles = {
  body: {
    background: '#ECEFF1',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    margin: 0,
    padding: 0,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    background: 'white',
    maxWidth: '360px',
    padding: '32px 24px 16px',
    borderRadius: '3px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  h1: {
    fontSize: '22px',
    fontWeight: 300,
    color: 'rgba(0,0,0,0.6)',
    margin: '0 0 16px',
  },
  h2: {
    color: '#ffa100',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0 0 8px',
  },
  h3: {
    color: '#888',
    fontWeight: 'normal',
    fontSize: '16px',
    margin: '16px 0 12px',
  },
  p: {
    lineHeight: '140%',
    margin: '16px 0 24px',
    fontSize: '14px',
  },
};

export default NotFoundPage;
