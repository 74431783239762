import React, { useEffect, useState, useRef } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import './css/Home.css';
import 'chart.js/auto'

import { onAuthStateChanged, signOut } from 'firebase/auth';

import { db, auth } from './firebase';
import { doc, getDoc ,collection, getDocs, DocumentData } from 'firebase/firestore';

const getPastDates = (days: number): string[] => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < days; i++) {
        const pastDate = new Date();
        pastDate.setDate(today.getDate() - i);
        const year = pastDate.getFullYear();
        const month = (pastDate.getMonth() + 1).toString().padStart(2, '0');
        const day = pastDate.getDate().toString().padStart(2, '0');
        dates.push(`${year}-${month}-${day}`);
    }
    return dates.reverse(); // 1日前から7日前の順番で返す
};

const Home = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [monthSession, setMonthSession] = useState(0);
    const [monthSubmit, setMonthSubmit] = useState(0);

    const [totalSession, setTotalSession] = useState(0);
    const [totalSubmit, setTotalSubmit] = useState(0);

    const [questionA, setQuestionA] = useState('');
    const [questionB, setQuestionB] = useState('');
    const [questionC, setQuestionC] = useState('');

    const [userCommentList, setUserCommentList] = useState(['表示できるデータがありません']);
    const [spamCommentList, setSpamCommentList] = useState(['表示できるデータがありません']);

    const [reviewData, setReviewData] = useState({
        labels: ['6日前', '5日前', '4日前', '3日前', '2日前', '1日前', '今日'],
        datasets: [
            {
            label: '投稿',
            data: [0, 0, 0, 0, 0, 0, 0], // 固定のレビュー数データ
            fill: false,
            borderColor: 'rgba(0,123,255,1)',
            tension: 0.1,
            borderWidth: 2 // 線の太さ
            },
            {
            label: '閲覧',
            data: [0, 0, 0, 0, 0, 0, 0], // これを後で Firestore のデータで更新
            fill: false,
            borderColor: 'rgba(192,75,75,1)',
            tension: 0.1,
            borderWidth: 1  // 線の太さ
            }
        ]
    });

    const [pieDataA, setPieDataA] = useState({
        labels: ['', '', ''],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ]
    });

    const [pieDataB, setPieDataB] = useState({
        labels: ['', '', ''],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ]
    });

    const [pieDataC, setPieDataC] = useState({
        labels: ['', '', ''],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ]
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setIsSubmitting(true);
          if (user) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const reviewId = userDoc.data()?.reviewId;
                    if (reviewId) {
                        const reviewDocRef = doc(db, 'reviewPageIds', reviewId);

                        // ---------- 質問・回答 -------------
                        // データを取得
                        const docSnap = await getDoc(reviewDocRef);
                        if (docSnap.exists()) {
                            const data = docSnap.data();

                            setQuestionA(data.question1.text);
                            setQuestionB(data.question2.text);
                            setQuestionC(data.question3.text);

                            const questionAlabels = data.question1.options
                            const questionBlabels = data.question2.options
                            const questionClabels = data.question3.options

                            const questionAcounts = Object.values(data.question1.counts).map(value => Number(value));
                            const questionBcounts = Object.values(data.question2.counts).map(value => Number(value));
                            const questionCcounts = Object.values(data.question3.counts).map(value => Number(value));
                            
                            // 円グラフのパラメータセット
                            setPieDataA({
                                labels: questionAlabels,
                                datasets: [
                                    {
                                        data: questionAcounts,
                                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']  // カラー設定
                                    }
                                ]
                            });

                            // 円グラフのパラメータセット
                            setPieDataB({
                                labels: questionBlabels,
                                datasets: [
                                    {
                                        data: questionBcounts,
                                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']  // カラー設定
                                    }
                                ]
                            });

                            // 円グラフのパラメータセット
                            setPieDataC({
                                labels: questionClabels,
                                datasets: [
                                    {
                                        data: questionCcounts,
                                        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']  // カラー設定
                                    }
                                ]
                            });

                            // --------- ユーザレビューセット ---------
                            const normalComments = data.normalComments;
                            if(normalComments && normalComments.length > 0){
                                normalComments.reverse(); // 配列を直接逆順にする
                                setUserCommentList(normalComments);
                            }

                            const spamComments = data.spamComments;
                            if(spamComments && spamComments.length > 0){
                                spamComments.reverse(); // 配列を直接逆順にする
                                setSpamCommentList(spamComments);
                            }
                        }

                        // --------- 閲覧・投稿数 -----------
                        // サブコレクション "stats" の参照
                        const statsCollectionRef = collection(reviewDocRef, 'stats');
                        // サブコレクション内の全ドキュメントを取得
                        const querySnapshot = await getDocs(statsCollectionRef);
                        
                        // 過去7日間の日付リストを取得
                        const pastDates = getPastDates(7);

                        const monthDates = getPastDates(30);

                        // 7日分のデータを初期化
                        let sessionData = Array(7).fill(0); // 閲覧数
                        let submitData = Array(7).fill(0);  // 投稿数

                        let sessionMonthData = 0; // 閲覧数
                        let submitMonthData = 0;

                        let sessionTotalData = 0; // 閲覧数
                        let submitTotalData = 0;

                        // ドキュメントのデータを取得
                        querySnapshot.forEach((doc) => {
                            if(doc.id === 'session'){
                                const data = doc.data();
                                // 過去7日間のデータに対応する値を抽出
                                pastDates.forEach((date, index) => {
                                    if (data[date] !== undefined) {
                                        sessionData[index] = data[date]; // セッション数をセット
                                    }
                                });

                                // 過去30日間の合計
                                monthDates.forEach((date, index) => {
                                    if (data[date] !== undefined) {
                                        sessionMonthData += data[date]; // セッション数をセット
                                    }
                                });

                                // dataオブジェクト内の全てのキーの値を合計
                                Object.keys(data).forEach((key) => {
                                    const value = data[key];
                                    if (typeof value === 'number') {  // 値が数値の場合のみ加算
                                        sessionTotalData += value;
                                    }
                                });

                            }else if(doc.id === 'submit'){
                                const data = doc.data();
                                // 過去7日間のデータに対応する値を抽出
                                pastDates.forEach((date, index) => {
                                    if (data[date] !== undefined) {
                                        submitData[index] = data[date]; // セッション数をセット
                                    }
                                });

                                // 過去30日間の合計
                                monthDates.forEach((date, index) => {
                                    if (data[date] !== undefined) {
                                        submitMonthData += data[date]; // セッション数をセット
                                    }
                                });

                                // dataオブジェクト内の全てのキーの値を合計
                                Object.keys(data).forEach((key) => {
                                    const value = data[key];
                                    if (typeof value === 'number') {  // 値が数値の場合のみ加算
                                        submitTotalData += value;
                                    }
                                });
                            }
                        });

                        // reviewData に閲覧データを適用して更新
                        setReviewData(prevData => ({
                            ...prevData,
                            datasets: [
                                {
                                    ...prevData.datasets[0],
                                    data: submitData // 投稿数更新
                                },
                                {
                                    ...prevData.datasets[1],
                                    data: sessionData // 閲覧数更新
                                }
                            ]
                        }));

                        setMonthSession(sessionMonthData);
                        setMonthSubmit(submitMonthData);

                        setTotalSession(sessionTotalData);
                        setTotalSubmit(submitTotalData);
                        
                    }
                }
              setIsSubmitting(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsSubmitting(false);
            }
          }else{
            setIsSubmitting(false);
          }
        });
    
        return () => {
            unsubscribe();
        };
      }, []);


    return (
        <div className="home-container">
            {/* Review Box */}
            <div className="home-review-box">
                <div className="review-counts">
                    <p>累計閲覧数: {totalSession}</p>
                    <p>累計レビュー数: {totalSubmit}</p>
                    <p>30日間の閲覧数:{monthSession}</p>
                    <p>30日間のレビュー数:{monthSubmit}</p>
                </div>
                <div className="home-line-chart-container">
                    <Line data={reviewData} />
                </div>
            </div>

            {/* Answer Percentage */}
            <div className="home-answer-section">
               <div className="home-answer-box">
                    <h3 className="home-subheading">{questionA}</h3>
                    <Pie data={pieDataA} />
                </div>
                <div className="home-answer-box">
                    <h3 className="home-subheading">{questionB}</h3>
                    <Pie data={pieDataB} />
                </div>
                <div className="home-answer-box">
                    <h3 className="home-subheading">{questionC}</h3>
                    <Pie data={pieDataC} />
                </div>
            </div>

            {/* Comments Section */}
            <div className="home-comments-container">
                <div className='home-comments-box '>
                    <div className="home-comments-header">
                        <h3 className="home-subheading">レビューサイトへ誘導した口コミ</h3>
                    </div>
                    <div className="home-spam-comments">
                        <ul className="home-comment-list">
                            {/* userCommentList 配列を動的に描画 */}
                            {userCommentList.map((comment, index) => (
                                <li key={index} className="home-comment-item">
                                    {comment}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className='home-comments-box '>
                    <div className="home-comments-header">
                        <h3 className="home-subheading">スパムとみなされた口コミ</h3>
                    </div>
                    <div className="home-user-comments">
                        <ul className="home-comment-list">
                            {/* userCommentList 配列を動的に描画 */}
                            {spamCommentList.map((comment, index) => (
                                <li key={index} className="home-comment-item">
                                    {comment}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;