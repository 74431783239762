import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReviewForm from './review-form';
import ThanksPage from './thanks-page'
import LoginPage from './Login'
import MemberPage from './MyPage';
import NotFoundPage from './NotFoundPage';
import Quit from './quit';
import PasswordResetPage from './PasswordResetPage';
import './index.css'; // CSSファイルのインポート

const container = document.getElementById('root');
const root = createRoot(container!); // コンテナがnullでないことを保証するために `!` を使用

const currentHost = window.location.hostname; // 現在のホスト名を取得
const checkHost = (allowedHost: string, currentHost: string) => {
    return allowedHost === currentHost; // 単体の文字列で比較
  };

root.render(
    <Router>
        <Routes>
            {checkHost('review.kuchikomi-fueru.com',currentHost) &&(
                <Route path="/:docId" element={<ReviewForm />} />
            )}

            {checkHost('service.kuchikomi-fueru.com', currentHost) && (
                <>
                <Route path="/thanks" element={<ThanksPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/mypage" element={<MemberPage />} />
                <Route path="/quit" element={<Quit />} />
                <Route path="/password-reset" element={<PasswordResetPage />} />
                </>
            )}
            
            {/* 他のルートもここに設定 */}

            {/* 404ページ: いずれのパスにも一致しない場合 */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </Router>,
);
