import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { db, auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getDoc, doc, onSnapshot, Timestamp } from 'firebase/firestore';

import './css/MemberPage.css'; // CSSファイルのインポート
import EditMemberInfo from './EditMemberInfo';
import QRCodeGenerator from './QRCodeGenerator';
import WrappedCardRegistration from './CardRegistration';
import ContactForm from './Contact';
import StartForm from './StartForm';
import IsReady from './isReady';
import Home from './Home';
import SubScriber from './SubScriber';
import SubScribReCreate from './SubScribReCreate';
import FreeStartDialog from './FreeStartDialog';
import ChangePasswordForm from './ChangePasswordForm';

import './css/PaymentNotice.css';

import logo from './image/logo.png'; // ロゴ画像のパスに合わせて変更

import { addUser, tutorial_finished } from './api'

const formatFirestoreDate = (timestamp: Timestamp): string => {
    const date = timestamp.toDate();  // FirestoreのTimestampをDateに変換

    // 年、月、日を取得
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月は0始まりのため +1し、2桁にする
    const day = ('0' + date.getDate()).slice(-2);  // 日を2桁にする

    // yyyy年mm月dd日のフォーマットで返す
    return `${year}年${month}月${day}日`;
};

const MemberPage: React.FC = () => {
    const [userEmail, setUserEmail] = useState<string | null>(null); // 表示するメール
    const [endDate, setEndDate] = useState<string | null>(null); //
    const [status, setStatus] = useState<string | null>(null); // サブスク状態
    const [cancel, setCancel] = useState<boolean>(false); // さぶすくキャンセル判定
    const [tutorial, setTutorial] = useState(false); // チュートリアル有効無効
    const [subscriptionstatus, setsubscriptionstatus] = useState<string | null>(null); //
    const [trialValid, setTrialValid] = useState(false);

    const [showSidebar, setShowSidebar] = useState(false);
    const [showEditInfo, setShowEditInfo] = useState(false);
    const [showEditReview, setShowEditReview] = useState(false);
    const [showEditPayment, setShowEditPayment] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showLogoutDialog, setShowLogoutDialog] = useState(false); // ログアウト確認ダイアログの状態管理
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);  // フォーム送信後の状態管理
    const [isButtonEnabled, setIsButtonEnabled] = useState(false); // ボタン無効
    const [showReady, setShowReady] = useState(false); // 準備中画面
    const [showHome, setShowHome] = useState(false);
    const [showSubScriber, setShowSubScriber] = useState(false);
    const [showReCreate, setShowReCreate] = useState(false);
    const [showPasswordChaneg, setShowPasswordChaneg] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(true); // ローディング状態を管理

    const navigate = useNavigate();

    // ---- ユーザの状態をDBから取得 ---
    // --- ユーザ情報がDBにない (初回ログイン) 場合、DB作成 ---
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsSubmitting(true);
            if (user) {
                const uid = user.uid;

                try {
                    const idToken = await user.getIdToken(); // Firebase Authentication トークン取得

                    const userDocRef = doc(db, 'users', uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const email = user.email;
                        setTutorial(userDocSnap.data().tutorial);
                        setStatus(userDocSnap.data().status);
                        setCancel(userDocSnap.data().cancel);
                        setEndDate(formatFirestoreDate(userDocSnap.data().endDate));
                        setUserEmail(email);
                         
                        const now = new Date(); // 現在の日時
                        const targetDate = userDocSnap.data().endDate.toDate();
                        setTrialValid(targetDate > now);
                    } else {
                        await addUser(idToken);
                        // ユーザーデータを再取得
                        const newUserDocSnap = await getDoc(userDocRef);
                        if (newUserDocSnap.exists()) {
                            const email = user.email;
                            setTutorial(newUserDocSnap.data().tutorial);
                            setStatus(newUserDocSnap.data().status);
                            setCancel(newUserDocSnap.data().cancel);
                            setEndDate(formatFirestoreDate(newUserDocSnap.data().endDate));
                            setUserEmail(email);

                            const now = new Date(); // 現在の日時
                            const targetDate = newUserDocSnap.data().endDate.toDate();
                            setTrialValid(targetDate > now); 
                        }
                    }
                } catch (error) {
                    console.error('Error fetching ID token or user data:', error);
                } finally {
                    setIsSubmitting(false);
                }
            } else {
                setIsSubmitting(false);
                navigate('/login');
            }
        });

        return () => {
            unsubscribe();
        };
}, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const sidebar = document.querySelector('.sidebar');
            const hamburger = document.querySelector('.hamburger');
            
            // クリックした場所がサイドバーやハンバーガーメニューでない場合、サイドバーを閉じる
            if (sidebar && !sidebar.contains(event.target as Node) && 
                hamburger && !hamburger.contains(event.target as Node)) {
                setShowSidebar(false);
            }
        };
    
        // クリックイベントを監視
        document.addEventListener('click', handleOutsideClick);
    
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleClose = async () => {
        try {
            setTutorial(false);
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken();
                await tutorial_finished(idToken);
            }
        } catch (error) {
            console.error('エラー:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('ログアウトエラー:', error);
        }
    };

    

    const confirmLogout = () => {
        setShowLogoutDialog(true); // ポップアップ表示
    };

    const cancelLogout = () => {
        setShowLogoutDialog(false); // ポップアップ非表示
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    // 会員情報変更
    const handleEdit = () => {
        setShowEditPayment(false);
        setShowEditReview(false);
        setShowContact(false);
        setShowReady(false);
        setShowHome(false);
        setShowSubScriber(false);
        setShowReCreate(false);
        setShowPasswordChaneg(false);
        setShowEditInfo(true);

        setShowSidebar(false);
    };

    // マイページTOP
    // 0 : ホーム画面
    const mypageTop = () => {
        setShowEditPayment(false);
        setShowEditReview(false);
        setShowContact(false);
        setShowReady(false);
        setShowEditInfo(false);
        setShowHome(false);
        setShowSidebar(false);
        setShowSubScriber(false);
        setShowReCreate(false);
        setShowPasswordChaneg(false);
    };

    const passwordChange = () => {
        setShowEditPayment(false);
        setShowEditReview(false);
        setShowContact(false);
        setShowReady(false);
        setShowEditInfo(false);
        setShowHome(false);
        setShowSidebar(false);
        setShowSubScriber(false);
        setShowReCreate(false);

        setShowPasswordChaneg(true);
    };

    const reviewEdit = () => {
        setShowPasswordChaneg(false);
        setShowEditPayment(false);
        setShowEditInfo(false);
        setShowContact(false);
        setShowHome(false);
        setShowReCreate(false);
        setShowEditReview(true);
        setShowSidebar(false);
        setShowSubScriber(false);
    };

    const paymeneEdit = () => {
        setShowPasswordChaneg(false);
        setShowEditInfo(false);
        setShowEditReview(false);
        setShowContact(false);
        setShowReady(false);
        setShowHome(false);
        setShowSubScriber(false);
        setShowReCreate(false);
        setShowEditPayment(true);
        setShowSidebar(false);
    };

    const contactEdit = () => {
        setShowPasswordChaneg(false);
        setShowEditInfo(false);
        setShowEditReview(false);
        setShowEditPayment(false);
        setShowReady(false);
        setShowHome(false);
        setShowSubScriber(false);
        setShowReCreate(false);
        setShowContact(true);

        setShowSidebar(false);
    };

    const subscriptionCreate = () => {
        setShowPasswordChaneg(false);
        setShowEditInfo(false);
        setShowEditReview(false);
        setShowEditPayment(false);
        setShowReady(false);
        setShowHome(false);
        setShowContact(false);
        setShowSidebar(false);
        setShowReCreate(false);

        setShowSubScriber(true);
    }

    const subscriptionReCreate = () => {
        setShowPasswordChaneg(false);
        setShowEditInfo(false);
        setShowEditReview(false);
        setShowEditPayment(false);
        setShowReady(false);
        setShowHome(false);
        setShowContact(false);
        setShowSidebar(false);
        setShowSubScriber(false);
        
        setShowReCreate(true);
    }

    return (
        <div className="container">
            <div className="header">
                <img src={logo} alt="Logo" className="logo" />
                
                <button className="hamburger" onClick={toggleSidebar}>
                    &#9776;
                </button>
            </div>
            <div className={`main-content ${showSidebar ? 'sidebar-open' : ''}`}>
                <div className={`sidebar ${showSidebar ? 'open' : ''}`}>
                    {userEmail ? (
                        <p className="text-sm sm:text-base text-gray-600">ログインID: {userEmail}</p>
                    ) : (
                        <p className="text-sm sm:text-base text-gray-600">読み込み中...</p>
                    )}
                    

                    {/* 会員状態表示 */}
                    {status ? (
                        status === "active" ? (
                            <>
                            <p>プラン: 有料</p>
                            {cancel && (
                                <p>次回更新で停止されます</p>
                            )}
                            </>
                        ) : status === "trialing" ? (
                            <>
                            <p>プラン: トライアル</p>
                            {cancel && (
                                <p>次回更新で停止されます</p>
                            )}
                            </>
                        ) : status === 'free' ? (

                            trialValid === true ? (
                                <>
                                <p>プラン: トライアル</p>
                                <p className="paidchange" onClick={subscriptionCreate}>継続利用はこちらから</p>
                                </>
                            ):(
                                <>
                                    <p>プラン: 無料</p>
                                    <p className="paidchange" onClick={subscriptionCreate}>継続利用はこちらから</p>
                                </>
                            )
                        ) : status === 'ready' || status === 'unready' ? (
                            <p>プラン: 準備中</p>
                        ) : status === 'canceled' ? (
                            <>
                            <p>プラン: 無料</p>
                            <p className="paidchange" onClick={subscriptionReCreate}>継続利用はこちらから</p>
                            </>
                        ) : (
                            <p>プラン: 無料</p>
                        )
                    ) : (
                        <p>プラン: 準備中</p>
                    )}
                    
                    <div className="menu-buttons">
                        <button className="menu-button" onClick={mypageTop}>
                            ダッシュボード
                        </button>

                        <button className="menu-button" onClick={passwordChange}>
                            パスワード変更
                        </button>
                        
                        <button 
                            className="menu-button" 
                            onClick={paymeneEdit}
                            disabled={status === 'free' || status === 'unready' || status === 'ready'}>
                            契約内容管理
                        </button>

                        <button 
                            className="menu-button" 
                            onClick={handleEdit}
                            disabled={status === 'free' || status === 'unready' || status === 'ready'}>
                            領収書発行
                        </button>
                        
                        <button 
                            className="menu-button" 
                            onClick={reviewEdit}
                            disabled={(status != 'free' && status != 'active' && status != 'trialing') || (status === 'free' && !trialValid)}>
                            ＱＲコード
                        </button>
                        
                        <button 
                            className="menu-button" 
                            onClick={contactEdit}>
                            お問い合わせ
                        </button>
                        
                        <button className="menu-button logout-button" onClick={confirmLogout}>
                            ログアウト
                        </button>
                    </div>
                </div>
                <div className="content">
                    <div className="block">
                        { showPasswordChaneg ? (
                            < ChangePasswordForm />
                        ): showSubScriber ? (
                            < SubScriber />
                        ): showReCreate ? (
                            < SubScribReCreate />
                        ):  showContact ? (
                            <ContactForm />
                        ) : showEditPayment ? (
                            <WrappedCardRegistration subscriptionReCreate={subscriptionReCreate} />
                        ) : showEditInfo ? (
                            <EditMemberInfo />
                        ) : showEditReview ? (
                            <QRCodeGenerator />
                        ) : status === 'ready' ? (
                            <IsReady />
                        ) : status === 'free' && !trialValid ? (
                            <>
                                <div className="payment-notice">
                                    <div>
                                        <p>トライアルの有効期限は {endDate} までとなります。</p>
                                        <p>期間終了後も引き続きサービスをご利用いただくためには、<a href="#" onClick={subscriptionCreate}>決済情報のご登録</a>が必要です。</p>
                                    </div>
                                </div>
                                <div className="canceled-container">
                                < Home />
                                </div>
                            </>
                        
                        ) : status === 'free' ? (
                            
                            <>
                            <div className="payment-notice">
                                <div>
                                <p>トライアルの有効期限は {endDate} までとなります。</p>
                                <p>期間終了後も引き続きサービスをご利用いただくためには、<a href="#" onClick={subscriptionCreate}>決済情報のご登録</a>が必要です。</p>
                                </div>
                            </div>
                            < Home />
                            </>
                            
                        ) : status === 'canceled' ? (
                            <>
                            <div className="payment-notice">
                                <div>
                                <p>サブスクリプションの有効期限が過ぎています。</p>
                                <p>サービスをご利用いただくためには、<a href="#" onClick={subscriptionReCreate}>サブスクリプションを有効化</a>してください。</p>
                                </div>
                            </div>
                            <div className="canceled-container">
                            < Home />
                            </div>
                            </>
                        ): status === 'unready' ?(
                            <StartForm />
                        ) : status === 'unpaid' || status === 'past_due' || status === 'incomplete' ? (
                            <>
                                <div className="payment-notice">
                                    <div>
                                        <p>サブスクリプションが無効になっています。</p>
                                        <p>サービスをご利用いただくためには、カードの支払いを確認のうえ有効化してください。</p>
                                    </div>
                                </div>
                                <div className="canceled-container">
                                    < Home />
                                </div>
                            </>
                        ) : (
                            < Home />
                        )}
                    </div>
                </div>
            </div>

            {/* ログアウト確認ダイアログ */}
            {showLogoutDialog && (
                <div className="logout-dialog">
                    <div className="logout-dialog-content">
                        <p>ログアウトしますか？</p>
                        <button onClick={handleLogout} className="btn-confirm">
                            はい
                        </button>
                        <button onClick={cancelLogout} className="btn-cancel">
                            いいえ
                        </button>
                    </div>
                </div>
            )}

            {/* 初期ダイアログ */}
            {tutorial && (
                <FreeStartDialog handleClose={handleClose} />
            )}

            {/* ロード画面 */}
            {isSubmitting && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

export default MemberPage;
