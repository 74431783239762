import React from 'react';

const Quit: React.FC = () => {
    return (
        <div>
            <h2>退会が完了しました。</h2>
            <p>クチコミフエールをご利用いただきありがとうございました。</p>
        </div>
    );
};

export default Quit;
