import React, { useState, useEffect } from 'react';
import './css/ChangePasswordForm.css'; // スタイルシートをインポート
import { auth } from './firebase'; // Firebase認証モジュールをインポート
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート

import { killSession } from './api'

import {
  User,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  signOut
} from 'firebase/auth'; // Firebase関数をインポート

const ChangePasswordForm: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>(''); // 現在のパスワード
  const [newPassword, setNewPassword] = useState<string>(''); // 新しいパスワード
  const [confirmPassword, setConfirmPassword] = useState<string>(''); // 確認用パスワード
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true); // 送信ボタンの制御
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // ローディング状態管理
  const [passwordError, setPasswordError] = useState<string>(''); // パスワードエラーの状態管理
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>(''); // 確認パスワードエラー
  const navigate = useNavigate(); // useNavigateを取得

  // パスワード検証関数
  const validatePassword = (password: string): boolean => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password); // 大文字を含む
    const hasLowerCase = /[a-z]/.test(password); // 小文字を含む
    const hasNumber = /[0-9]/.test(password); // 数字を含む
    const isValidLength = password.length >= minLength;

    return hasUpperCase && hasLowerCase && hasNumber && isValidLength;
  };

  // useEffectで送信ボタンの制御
  useEffect(() => {
    const isDisabled =
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword ||
      !validatePassword(newPassword);
    setIsSubmitDisabled(isDisabled);
  }, [currentPassword, newPassword, confirmPassword]);

  // パスワード更新処理
  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('新しいパスワードが一致していません。');
      return;
    } else {
      setConfirmPasswordError('');
    }

    if (!validatePassword(newPassword)) {
      setPasswordError('パスワードはポリシーを満たしていません。');
      return;
    } else {
      setPasswordError('');
    }

    setIsSubmitting(true);

    try {
      const user = auth.currentUser as User | null; // User 型を指定して currentUser を取得
      
      if (user && currentPassword) {
        // 現在のパスワードで再認証
        const credential = EmailAuthProvider.credential(user.email || '', currentPassword); // EmailAuthProviderを使用
        await reauthenticateWithCredential(user, credential);

        // IDトークンを取得
        const idToken = await user.getIdToken();

        // パスワードの更新処理
        await updatePassword(user, newPassword);
        alert('パスワードが正常に変更されました。');
        
        // セッションキル
        killSession(idToken);
        // ユーザーをサインアウト
        await signOut(auth);
        navigate('/login'); // ログインページへリダイレクト
      }
    } catch (error) {
      alert('パスワードの変更中にエラーが発生しました。');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="change-password-container">
      <h1 className="change-password-header">パスワード変更</h1>
      <form className="change-password-form" onSubmit={handleChangePassword}>
        <label className="change-password-label">
          現在のパスワード:
          <input
            type="password"
            className="change-password-input"
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            required
          />
        </label>

        <label className="change-password-label">
          新しいパスワード:
          <input
            type="password"
            className="change-password-input"
            value={newPassword}
            onChange={(e) => {
              const password = e.target.value;
              setNewPassword(password);

              if (!validatePassword(password)) {
                setPasswordError(
                  'パスワードは8文字以上で、大文字、小文字、数字を含める必要があります。'
                );
              } else {
                setPasswordError('');
              }
            }}
            required
          />
        </label>
        {passwordError && <p className="pw-error-message">{passwordError}</p>}

        <label className="change-password-label">
          新しいパスワード（確認）:
          <input
            type="password"
            className="change-password-input"
            value={confirmPassword}
            onChange={(e) => {
              const confirmPass = e.target.value;
              setConfirmPassword(confirmPass);

              if (newPassword !== confirmPass) {
                setConfirmPasswordError('新しいパスワードが一致していません。');
              } else {
                setConfirmPasswordError('');
              }
            }}
            required
          />
        </label>
        {confirmPasswordError && <p className="pw-error-message">{confirmPasswordError}</p>}

        <button
          type="submit"
          className="change-password-submit-button"
          disabled={isSubmitDisabled || isSubmitting}
        >
          {isSubmitting ? '送信中...' : 'パスワードを変更'}
        </button>
      </form>

      {/* ローディング中のスピナー */}
      {isSubmitting && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordForm;
